<template>
  <a-row :gutter="15">
    <a-col class="w-100" :md="9">
      <CardStyleWrapper>
        <sdCards :title="'Information'" :border="true" size="default">
          <FormValidationWrap>
            <VerticalFormStyleWrap>
              <a-form
                name="sDash_validation-form"
                ref="formRef"
                @finish="onSubmitBankInfo"
                :model="bankState"
                :rules="BankRules"
                :layout="bankState.layout"
              >
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="banque" name="banque" label="Bank">
                      <a-input
                        v-model:value="bankState.banque"
                        placeholder="Bank"
                      />
                    </a-form-item>
                    <a-form-item
                      ref="numeroCompte"
                      name="numeroCompte"
                      label="Account number"
                    >
                      <a-input
                        v-model:value="bankState.numeroCompte"
                        placeholder="Account number"
                      />
                    </a-form-item>
                    <a-form-item ref="rib" name="rib" label="Rib">
                      <a-input
                        v-model:value="bankState.rib"
                        placeholder="Rib"
                      />
                    </a-form-item>
                    <a-form-item ref="iban" name="iban" label="IBAN">
                      <a-input
                        v-model:value="bankState.iban"
                        placeholder="IBAN"
                      />
                    </a-form-item>
                    <a-form-item ref="codeSwift" name="codeSwift" label="Swift">
                      <a-input
                        v-model:value="bankState.codeSwift"
                        placeholder="Swift"
                      />
                    </a-form-item>
                    <a-form-item label="Currency" name="currencyId">
                      <a-select
                        v-model:value="bankState.currencyId"
                        placeholder="Currency"
                      >
                        <a-select-option
                          v-for="(currency, index) in CurrencyApi"
                          :key="index"
                          :value="currency.id"
                        >
                          {{ currency.name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <div class="sDash_form-action">
                  <sdButton
                    v-if="bankState.editionMode"
                    v-on:click="handleBankInfoCancelEdit()"
                    class="sDash_form-action__btn"
                    size="large"
                    type="light"
                  >
                    Cancel
                  </sdButton>
                  <sdButton
                    v-if="bankState.editionMode"
                    type="primary"
                    class="sDash_form-action__btn"
                    size="large"
                  >
                    Edit
                  </sdButton>
                  <sdButton
                    v-else
                    type="primary"
                    html-type="submit"
                    class="sDash_form-action__btn"
                    size="large"
                  >
                    Add
                  </sdButton>
                </div>
              </a-form>
            </VerticalFormStyleWrap>
          </FormValidationWrap>
        </sdCards>
      </CardStyleWrapper>
    </a-col>
    <a-col :md="15">
      <a-row :gutter="15">
        <a-col :md="12" v-for="(bank, index) in bankData" :key="index">
          <CardStyleWrapper>
            <sdCards
              :title="bank.banque"
              :border="true"
              size="small"
              :moreText="true"
            >
              <template #button>
                <sdButton @click="handleBankInfoEdit(bank)">
                  <sdFeatherIcons type="edit" size="16" />
                </sdButton>
                <sdButton @click="handleBankInfoDelete(bank)">
                  <sdFeatherIcons type="trash-2" size="16" />
                </sdButton>
              </template>
              <p>Account: {{ bank.numeroCompte }}</p>
              <p>RIB: {{ bank.rib }}</p>
              <p>IBAN: {{ bank.iban }}</p>
              <p>Swift: {{ bank.codeSwift }}</p>
              <p>Currency: {{ bank.currencyName }}</p>
            </sdCards>
          </CardStyleWrapper>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
<script>
///////

import { computed, onMounted, reactive, ref, defineComponent, nextTick } from "vue";
import { useStore } from "vuex";
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../../forms/overview/Style";
import { useRoute } from "vue-router";
import { CardStyleWrapper } from "../../../view/uiElements/ui-elements-styled";

const bankDetailsColumns = [
  {
    title: "Currency",
    dataIndex: "bankDetail.currencyName",
    key: "bankDetail.currencyName",
  },
  {
    title: "Bank",
    dataIndex: "bankDetail.banque",
    key: "bankDetail.banque",
  },
  {
    title: "Account number",
    dataIndex: "bankDetail.numeroCompte",
    key: "bankDetail.numeroCompte",
  },
  {
    title: "RIB",
    dataIndex: "bankDetail.rib",
    key: "bankDetail.rib",
  },
  {
    title: "IBAN",
    dataIndex: "bankDetail.iban",
    key: "bankDetail.iban",
  },
  {
    title: "Swift",
    dataIndex: "bankDetail.codeSwift",
    key: "bankDetail.codeSwift",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const BankInfo = defineComponent({
  name: "BankInfo",
  props: {
    idPartnaire: String,
  },
  components: {
    FormValidationWrap,
    VerticalFormStyleWrap,
    CardStyleWrapper,
  },
  setup(props) {
    const { state, dispatch } = useStore();
    const showBankModal = ref(false);
    const { params } = useRoute();
    const bankData = computed(() => state.bankApi.data);
    const CurrencyApi = computed(() => state.CurrencyApi.data);
    const isLoading = computed(() => state.partnerApi.loading);
    const formRef = ref();

    var bankState = reactive({
      layout: "vertical",
      partenaireId: 0,
      banque: "",
      numeroCompte: "",
      rib: "",
      iban: "",
      codeSwift: "",
      currencyId: null,
      editionMode: false,
    });

    const BankRules = {
      banque: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      numeroCompte: [
        {
          required: true,
          message: "Account number is required",
          trigger: "change",
        },
      ],
      rib: [
        {
          required: true,
          message: "RIB is required",
          trigger: "change",
        },
      ],
      iban: [
        {
          required: true,
          message: "IBAN is required",
          trigger: "change",
        },
      ],
      codeSwift: [
        {
          required: true,
          message: "SWIFT is required",
          trigger: "change",
        },
      ],
      currencyId: [
        {
          required: true,
          message: "Currency is required",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("bankDetailsByPartner", params.id);
      dispatch("currencyInfo");
    });

    ////////// bank detail
    const onSubmitBankInfo = () => {
      bankState.partenaireId = props.idPartnaire;
      formRef.value
        .validate()
        .then(async () => {
          if (!bankState.editionMode) {
            delete bankState.id;
            dispatch("bankDetailsSubmitData", {
              bankState,
            });
            await nextTick();
            handleBankInfoCancelEdit();
          } else {
            dispatch("bankDetailsUpdateData", {
              bankState,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const handleBankInfoDelete = (bank) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("bankDetailsApiDataDelete", {
          id: bank.id,
        });
      }
      return false;
    };
    const handleBankInfoEdit = (bank) => {
      bankState.editionMode = true;
      bankState.id = bank.id;
      bankState.partenaireId = bank.partenaireId;
      bankState.partenaireName = bank.partenaireName;
      bankState.banque = bank.banque;
      bankState.numeroCompte = bank.numeroCompte;
      bankState.rib = bank.rib;
      bankState.iban = bank.iban;
      bankState.codeSwift = bank.codeSwift;
      bankState.currencyId = bank.currencyId;
    };
    const handleBankInfoCancelEdit = () => {
      bankState.editionMode = false;
      bankState.partenaireId = null;
      bankState.banque = "";
      bankState.numeroCompte = "";
      bankState.rib = "";
      bankState.iban = "";
      bankState.codeSwift = "";
      bankState.currencyId = null;
    };
    return {
      bankDetailsColumns,
      onSubmitBankInfo,
      isLoading,
      showBankModal,
      handleBankInfoDelete,
      handleBankInfoEdit,
      handleBankInfoCancelEdit,
      BankRules,
      bankData,
      bankState,
      formRef,
      CurrencyApi,
    };
  },
});

export default BankInfo;
</script>
<style lang="scss">
.ant-card-head-wrapper {
  flex: auto;
}
</style>
